<template>
	<!-- Authors Table Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">Competitors {{data.length}}</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<!-- <a-radio-group v-model="authorsHeaderBtns" size="small">
						<a-radio-button value="all">ALL</a-radio-button>
						<a-radio-button value="online">ACTIVE</a-radio-button>
					</a-radio-group> -->
				</a-col>
			</a-row>
		</template>
		<a-table :columns="columns" :data-source="data" :pagination="true">
			<template slot="editBtn" slot-scope="row">
				<a-button shape="circle" class="btn-edit" :data-id="row.key" @click="editcompetitor(row)" icon="edit">
				</a-button>
			</template>
			<template slot="delBtn" slot-scope="row">
				<a-button shape="circle" class="btn-edit" :data-id="row.key" @click="delcompetitor(row)" icon="delete">
				</a-button>
			</template>
		</a-table>
	</a-card>
	<!-- / Authors Table Card -->

</template>

<script>
	import { removecompetitor } from '@/api/competitor';

	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				// Active button for the "Authors" table's card header radio button group.
				authorsHeaderBtns: 'all',
			}
		},
		methods: {
			editcompetitor(id){
				this.$router.push({path:'/competitoredit',query:{competitorid:id}})
			},
			delcompetitor(id){
				removecompetitor(id).then(data=>{
					for (var i=0; i < this.data.length; i++)
						if (this.data[i].competitorid==id)
							this.data.splice(i,1);
				})
			}
		}
	})

</script>